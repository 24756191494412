<template>
    <span v-if="row.item.mobile">
        <a target="_blank" :href="'tel:'+row.item.mobile">{{ row.item.mobile }}</a>
        <a target="_blank"
           :href="'https://api.whatsapp.com/send/?phone='+cleanPhone(row.item.mobile)">
            <font-awesome-icon :icon="['fab', 'whatsapp']" class="ml-2 text-success"  size="lg"/>
        </a>
    </span>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {
        cleanPhone(phone) {
            let cleaned = phone.replace(/[^\d+]/g, '');
            if (cleaned.startsWith('+')) {
                cleaned = cleaned.substring(1);
            }

            return cleaned;
        }
    },
}
</script>